.App {
  position: relative;
  background-color: #0C212E;
}

video {
  border-radius: 10px;
}

.react-player {
  width: 100% !important; 
  height: auto !important; 
}
